import React, { useState, useMemo, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import TableFilter from './TableFilter';

const TableView = (props) => {
  const { data, client, country } = props;
  const [search, setSearch] = useState('');

  const tableData = useMemo(
    () =>
      data.map((item) => {
        const { asin, brand, classification, itemName, price, revenue, salesRank } = item;
        return {
          ASIN: asin,
          'Product Title': itemName,
          Brand: brand,
          Category: classification,
          'Sales Rank': salesRank,
          'Price (USD)': price,
          'Revenue (USD)': revenue,
        };
      }),
    [data]
  );

  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedClassifications, setSelectedClassifications] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [rowData, setrowData] = useState(tableData);

  useEffect(() => {
    const selectedData = tableData.filter((item) =>
      selectedBrands.length > 0 ? selectedBrands.includes(item.Brand) : true
    );
    setrowData(selectedData);
  }, [tableData,selectedBrands]);

  useEffect(() => {
    const selectedData = tableData.filter((item) =>
      selectedClassifications.length > 0 ? selectedClassifications.includes(item.Category) : true
    );
    setrowData(selectedData);
  }, [tableData,selectedClassifications]);

  useEffect(() => {
    const selectedData = tableData.filter((item) => (selectedTags.length > 0 ? selectedTags.includes(item.Tag) : true));
    setrowData(selectedData);
  }, [tableData,selectedTags]);

  useEffect(() => {
    const selectedData = tableData.filter((item) => (search ? item?.ASIN === search : true));
    setrowData(selectedData);
  }, [tableData,search]);

  const colDefs = useMemo(() => {
    return [
      { field: 'ASIN', checkboxSelection: true, headerCheckboxSelection: true },
      { field: 'Product Title' },
      { field: 'Brand' },
      { field: 'Category' },
      { field: 'Sales Rank' },
      { field: 'Price (USD)' },
      { field: 'Revenue (USD)' },
    ];
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
      flex: 1,
    };
  }, []);

  const gridOptions = {
    rowSelection: 'multiple',
  };
  /*
  const onSelectionChanged = (event) => {
    const selectedNodes = event.api.getSelectedNodes();
    const selectedData = selectedNodes.map((node) => node.data);
  };
  */
  return (
    <div>
      <TableFilter
        data={data}
        client={client}
        country={country}
        selectedBrands={selectedBrands}
        setSelectedBrands={setSelectedBrands}
        selectedClassifications={selectedClassifications}
        setSelectedClassifications={setSelectedClassifications}
        setSearch={setSearch}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
      />
      <div
        className="ag-theme-quartz m-3"
        style={{
          height: '200px',
          width: '95%',
        }}
      >
        <AgGridReact
          rowData={rowData}
          columnDefs={colDefs}
          defaultColDef={defaultColDef}
          gridOptions={gridOptions}
          //onSelectionChanged={onSelectionChanged}
        />
      </div>
    </div>
  );
};
export default TableView;
