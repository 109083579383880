export const calculateSymbolSize = (value, minValue, maxValue, minSize, maxSize) => {
  if (value === 0) {
    return value;
  }
  const normalizedValue = (value - minValue) / (maxValue - minValue);
  const size = Math.sqrt(normalizedValue) * (maxSize - minSize) + minSize;
  return Math.round(size);
};

export const calculateQuadrantBounds = (activeXAxis, activeYAxis, activeMarkers, quadrant) => {
  const [minX, maxX] = activeXAxis;
  const [minY, maxY] = activeYAxis;
  const { xDivider, yDivider } = activeMarkers;

  // Quadrant 1: Top-right
  const quadrants = {
    quadranttr: {
      minX: xDivider,
      maxX: maxX,
      minY: yDivider,
      maxY: maxY,
    },
    // Quadrant 2: Top-left
    quadranttl: {
      minX: minX,
      maxX: xDivider,
      minY: yDivider,
      maxY: maxY,
    },

    // Quadrant 3: Bottom-left
    quadrantbl: {
      minX: minX,
      maxX: xDivider,
      minY: minY,
      maxY: yDivider,
    },

    // Quadrant 4: Bottom-right
    quadrantbr: {
      minX: xDivider,
      maxX: maxX,
      minY: minY,
      maxY: yDivider,
    },
  };
  return quadrants[quadrant];
};

export const computeMarker = (data, markerType, customValue, axisType) => {
  let divider = '';
  if (markerType === 'custom' && customValue) {
    divider = parseInt(customValue);
  } else {
    const dataIndex = axisType === 'xAxis' ? 0 : axisType === 'yAxis' ? 1 : '';
    const axisData = data.map((item) => item[dataIndex]);
    if (markerType === 'median') {
      divider = calculateMedian(axisData);
    } else if (markerType === 'mean') {
      const sum = axisData.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
      divider = sum / axisData.length;
    }
  }
  return divider;
};

export const computeMarkers = (transformedData, xAxisMarkerType, yAxisMarkerType, xAxisMarker, yAxisMarker) => {
  const xDivider = computeMarker(transformedData, xAxisMarkerType, xAxisMarker, 'xAxis');
  const yDivider = computeMarker(transformedData, yAxisMarkerType, yAxisMarker, 'yAxis');
  return { xDivider, yDivider };
};

export const generateFilterSeries = (data, selectedFilter, filterType, markerData = {}, markerArea, activeItem) => {
  const d1 = data.filter((item) => selectedFilter.includes(item?.[4]?.[filterType]));
  const groupedData = d1?.reduce((acc, item) => {
    const [xAxisValue, yAxisValue, brand, classification, details, xAxis, yAxis] = item;
    const type = filterType === 'brand' ? brand : filterType === 'classification' ? classification : '';
    if (!acc[type]) {
      acc[type] = [];
    }
    acc[type].push([xAxisValue, yAxisValue, brand, classification, details, xAxis, yAxis]);
    return acc;
  }, {});
  const series = Object.keys(groupedData).map((item) =>
    generateSeries(groupedData[item], markerData, markerArea, activeItem, item)
  );
  return series;
};

export const transformData = (data, xAxis, yAxis) => {
  const xAxisTitle = chartAxisNameConversion(xAxis);
  const yAxisTitle = chartAxisNameConversion(yAxis);
  const filteredData = data?.filter((item) => item[xAxisTitle] && item[yAxisTitle]) ?? [];
  const result = filteredData.map((item) => {
    const xAxisValue = item[xAxisTitle];
    const yAxisValue = item[yAxisTitle];
    const brand = item?.['brand'] ? item['brand'] : '';
    const classification = item?.['classification'] ? item['classification'] : '';
    const details = item;
    return [xAxisValue, yAxisValue, brand, classification, details, xAxis, yAxis];
  });
  return result;
};

export const generateSeries = (data, markLineData = {}, markerArea, activeItem, name = '') => {
  const { xDivider, yDivider } = markLineData;

  const series = {
    name,
    type: 'scatter',
    data: data,
    symbolSize: 20,
    itemStyle: {
      color: (params) => {
        const { asin } = params?.data?.[4] ?? {};
        if (activeItem && activeItem?.asin === asin) {
          return 'red';
        }
        return params.color;
      },
    },
    markLine: {
      symbol: 'none',
      data: [...(xDivider ? [{ xAxis: xDivider }] : []), ...(yDivider ? [{ yAxis: yDivider }] : [])],
      lineStyle: {
        type: 'dashed',
        color: '#697077',
        width: 1,
      },
    },
    markArea: {
      silent: true,
      itemStyle: {
        color: 'rgba(255, 173, 177, 0.4)',
      },
      data: markerArea,
    },
  };
  return series;
};

export const updateDataZoom = (zoomState) => {
  const dataZoom = [
    {
      type: 'slider',
      id: 'xzoomslider',
      show: true,
      xAxisIndex: [0],
      top: 'bottom',
      showDataShadow: true,
      ...(zoomState?.xStart ? { startValue: zoomState.xStart } : {}),
      ...(zoomState?.xEnd ? { endValue: zoomState.xEnd } : {}),
    },
    {
      type: 'slider',
      id: 'yzoomslider',
      show: true,
      yAxisIndex: [0],
      left: '93%',
      ...(zoomState?.yStart ? { startValue: zoomState.yStart } : {}),
      ...(zoomState?.yEnd ? { endValue: zoomState.yEnd } : {}),
    },
    {
      type: 'inside',
      id: 'xzoominside',
      xAxisIndex: [0],
      ...(zoomState?.xStart ? { startValue: zoomState.xStart } : {}),
      ...(zoomState?.xEnd ? { endValue: zoomState.xEnd } : {}),
    },
    {
      type: 'inside',
      id: 'yzoominside',
      yAxisIndex: 0, // [0],
      ...(zoomState?.yStart ? { startValue: zoomState.yStart } : {}),
      ...(zoomState?.yEnd ? { endValue: zoomState.yEnd } : {}),
    },
  ];
  return dataZoom;
};

const chartAxisNameConversion = (title) => {
  if (title === 'conversion rate') {
    return 'conversionRate';
  } else if (title === 'net ppm') {
    return 'netPureProductMargin';
  } else if (title === 'on hand inventory') {
    return 'sellableOnHandInventoryUnits';
  } else {
    return 'revenue';
  }
};

export const getChartData = (data, type, xAxis, yAxis) => {
  if (!data?.length) return [];

  const xAxisTitle = chartAxisNameConversion(xAxis);
  const yAxisTitle = chartAxisNameConversion(yAxis);
  const filteredData = data?.filter((item) => item[xAxisTitle] && item[yAxisTitle]) ?? [];

  const matrix =
    filteredData?.reduce((acc, curr) => {
      const xAxisValue = curr[xAxisTitle];
      const yAxisValue = curr[yAxisTitle];
      const typeValue = curr[type];
      const key = `${xAxisValue.toFixed(2)}_${yAxisValue.toFixed(2)}_${typeValue}`;
      acc = acc[key] ? { ...acc, [key]: acc[key] + 1 } : { ...acc, [key]: 1 };
      return acc;
    }, {}) ?? [];

  const output =
    Object.keys(matrix)?.reduce((acc, curr) => {
      const [x, y, type] = curr.split('_');
      acc = [...acc, [x, y, matrix[curr], type, xAxis, yAxis]];
      return acc;
    }, []) ?? [];
  return output;
};

const calculateMedian = (data) => {
  data.sort((a, b) => a - b);
  const length = data.length;
  const middle = Math.floor(length / 2);
  if (length % 2 === 0) {
    return (data[middle - 1] + data[middle]) / 2;
  } else {
    return data[middle];
  }
};

export const generateMarkArea = (activeXAxis, activeYAxis, activeMarkers, quadrants) => {
  const markerArea = quadrants.map((quadrant) => {
    const range = calculateQuadrantBounds(activeXAxis, activeYAxis, activeMarkers, quadrant);
    const markerItem = [
      {
        name: quadrant,
        xAxis: range.minX,
        yAxis: range.minY,
      },
      {
        xAxis: range.maxX,
        yAxis: range.maxY,
      },
    ];
    return markerItem;
  });
  return markerArea;
};

const createCSV = (data) => {
  const headers = Object.keys(data[0]);
  const rows = data.map((row) =>
    Object.values(row)
      .map((item) => escapeCSVValue(item))
      .join(',')
  );
  return [headers.join(','), ...rows].join('\n');
};

const escapeCSVValue = (value) => {
  if (typeof value === 'string') {
    if (value.includes(',') || value.includes('"')) {
      value = value.replace(/,/g, ';').replace(/"/g, '');
    }
  }
  return value;
};

export const downloadCSV = (data) => {
  const csvContent = createCSV(data);
  const blob = new Blob([csvContent], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'data.csv';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const generateExportData = (
  data,
  activeXAxis,
  activeYAxis,
  activeMarkers,
  selectedFilter,
  filterType,
  quadrants
) => {
  let selectedData = data;
  if (filterType) {
    const filterIndex = filterType === 'brand' ? 2 : filterType === 'classification' ? 3 : '';
    selectedData = selectedData?.filter((item) => selectedFilter.includes(item[filterIndex])) ?? [];
  }
  if (quadrants.length > 0) {
    selectedData = quadrants.reduce((acc, quadrant) => {
      const { minX, maxX, minY, maxY } = calculateQuadrantBounds(activeXAxis, activeYAxis, activeMarkers, quadrant);
      const filteredData =
        selectedData?.filter((item) => item[0] >= minX && item[0] <= maxX && item[1] >= minY && item[1] <= maxY) ?? [];
      acc = [...acc, ...filteredData];
      return acc;
    }, []);
  }
  selectedData = selectedData?.map((item) => item[4]) ?? [];
  return selectedData;
};

export const generateChartSeries = (
  transformedData,
  selectedBrands,
  selectedClassifications,
  xDivider,
  yDivider,
  quadrants,
  filterType,
  activeProduct,
  activeAxis
) => {
  let series = [];
  const selectedFilter =
    selectedBrands?.length > 0 ? selectedBrands : selectedClassifications?.length > 0 ? selectedClassifications : [];
  const markerLines = { xDivider, yDivider };
  const { activeXAxis, activeYAxis } = activeAxis;
  const markerArea = quadrants.length > 0 ? generateMarkArea(activeXAxis, activeYAxis, markerLines, quadrants) : [];
  if (selectedFilter.length > 0) {
    series = generateFilterSeries(
      transformedData,
      selectedFilter,
      filterType,
      markerLines,
      markerArea,
      activeProduct,
      activeAxis
    );
  } else {
    series = [generateSeries(transformedData, markerLines, markerArea, activeProduct, activeAxis)] ?? [];
  }
  return { series, selectedFilter };
};
