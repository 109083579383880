import React, { useState, useEffect, useContext } from 'react';
import { WppButton } from '@wppopen/components-library-react';
import FilterView from './FilterView';
import ChartView from './ChartView';
import useFetchChartData from '../../hooks/useFetchChartData';
import useFilterOptions from '../../hooks/useFilterOptions';
import { ClientDataContext } from './PortfolioView';
const ReportView = () => {
  const { clientsData } = useContext(ClientDataContext);
  const [client, setClient] = useState('');
  const [country, setCountry] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [xAxis, setXAxis] = useState('netPpm');
  const [yAxis, setYAxis] = useState('conversionRate');
  const [nullIncluded, setNullIncluded] = useState(false);
  const { fetchData, requestStatus, data } = useFetchChartData();

  const { selectedStartDate, selectedEndDate } = useFilterOptions(clientsData, client);
  const handleUpdate = () => {
    fetchData(client, country, startDate, endDate, xAxis, yAxis, nullIncluded);
  };

  useEffect(() => {
    if (client) {
      setStartDate(selectedStartDate);
      setEndDate(selectedEndDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStartDate, selectedEndDate]);

  return (
    <div className="flex flex-col w-[90%]">
      <FilterView
        client={client}
        setClient={setClient}
        country={country}
        setCountry={setCountry}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        xAxis={xAxis}
        setXAxis={setXAxis}
        yAxis={yAxis}
        setYAxis={setYAxis}
        nullIncluded={nullIncluded}
        setNullIncluded={setNullIncluded}
      />
      <div className="mt-6 mr-6 place-self-end">
        <WppButton size="s" onClick={handleUpdate}>
          Update View
        </WppButton>
      </div>

      <div>
        <ChartView
          client={client}
          country={country}
          startDate={startDate}
          endDate={endDate}
          data={data.data}
          requestStatus={requestStatus}
          xAxis={xAxis}
          yAxis={yAxis}
        />
      </div>
    </div>
  );
};

export default ReportView;
