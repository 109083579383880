import React, { useMemo } from 'react';
import ReactEcharts from 'echarts-for-react';
import { calculateSymbolSize, getChartData } from '../../../utils/echartsUtils';

const ScatterChart = (props) => {
  const { data, xAxis, yAxis, chartType, onClick } = props;
  const chartData = useMemo(() => getChartData(data, chartType, xAxis, yAxis), [data, chartType,xAxis, yAxis]);
  const minValue = useMemo(() => Math.min(...chartData.map((item) => item[2])), [chartData]);
  const maxValue = useMemo(() => Math.max(...chartData.map((item) => item[2])), [chartData]);

  const option = {
    grid: {
      left: '10%',
      right: '10%',
      top: '10%',
    },
    tooltip: {
      trigger: 'item',
      formatter: (params) => {
        const [xValue, yValue, , title, xLabel, yLabel] = params.data;
        return `
         <strong>${title}</strong><br/>
        ${xLabel}: ${xValue}<br/>
         ${yLabel}: ${yValue}<br/>
        `;
      },
    },

    xAxis: {
      type: 'value',
      name: xAxis,

      nameLocation: 'center',
      nameGap: 30,
      splitLine: {
        show: true,
      },
    },
    yAxis: {
      type: 'value',
      name: yAxis,
      nameLocation: 'center',
      nameGap: 30,
      splitLine: {
        show: true,
      },
    },

    series: [
      {
        type: 'scatter',
        label: {
          show: true,
          formatter: ({ value }) => value[2] /*Size */,
        },
        labelLayout: {
          hideOverlap: true,
        },
        encode: { tooltip: [3] },
        data: chartData,
        symbolSize: (data) => {
          return calculateSymbolSize(data[2], minValue, maxValue, 10, 100);
        },
      },
    ],
    color: '#0A2FFF',
  };

  return (
    <div>
      <ReactEcharts
        option={option}
        style={{ minHeight: '400px', width: '100%' }}
        onEvents={{ click: onClick }}
        opts={{ renderer: 'svg' }}
      />
    </div>
  );
};
export default ScatterChart;
