import { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';

const baseApi = process.env.REACT_APP_API_ENDPOINT;

export const REQUEST_STATUS = {
  LOADING: 'loading',
  SUCCESS: 'success',
  FAILURE: 'failure',
};

const useFetchClients = () => {
  const [clientsData, setData] = useState([]);
  const [requestStatus, setRequestStatus] = useState(null);
  const [requestError, setRequestError] = useState(null);
  const { oktaAuth } = useOktaAuth();
  const accessToken = oktaAuth.getIdToken();

  useEffect(() => {
    async function fetchData() {
      try {
        setRequestStatus(REQUEST_STATUS.LOADING);
        const rcvd = await fetch(`${baseApi}/portfolio/clients_and_report_types`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const response = await rcvd.json();
        setData(response);
        setRequestStatus(REQUEST_STATUS.SUCCESS);
      } catch (error) {
        setRequestStatus(REQUEST_STATUS.FAILURE);
        setRequestError(error);
        console.log('API FETCH ERROR: ', error);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { clientsData, requestStatus, requestError };
};

export default useFetchClients;
