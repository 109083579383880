import React, { useState, useEffect } from 'react';
import { WppTypography, WppSpinner } from '@wppopen/components-library-react';
import ChartHeader from './ChartHeader';
import ScatterChart from './charts/ScatterChart';
import ProductView from './ProductView';
import { REQUEST_STATUS } from '../../hooks/useFetchClients';

const ChartView = (props) => {
  const { client, country, startDate, endDate, data, requestStatus, xAxis, yAxis } = props;
  const [chartType, setChartType] = useState('brand');
  const [selectedItem, setSelectedItem] = useState({});

  useEffect(() => {
    if (chartType !== 'products') setSelectedItem({});
  }, [chartType]);

  const handleClick = (e) => {
    setSelectedItem({ value: e?.value?.[3] ?? '', type: chartType });
    setChartType('products');
  };

  if (requestStatus === REQUEST_STATUS.LOADING) {
    return (
      <div className="flex items-center justify-center mt-6">
        <WppSpinner size="l" color="var(--wpp-primary-color-500)" />
      </div>
    );
  }
  if (requestStatus === null) {
    return <div className="flex items-center justify-center mt-6">No data to display</div>;
  }

  return (
    <div className="w-full bg-white mt-5">
      <ChartHeader chartType={chartType} setChartType={setChartType} data={{ client, startDate, endDate }} />
      {chartType === 'products' ? (
        <ProductView
          data={data}
          selectedItem={selectedItem}
          xAxis={xAxis}
          yAxis={yAxis}
          client={client}
          country={country}
        />
      ) : (
        <>
          <WppTypography className="ml-5 mt-4" type="xs-body">
            Click on the bubbles to get a breakdown by product
          </WppTypography>
          <ScatterChart data={data} xAxis={xAxis} yAxis={yAxis} chartType={chartType} onClick={handleClick} />
        </>
      )}
    </div>
  );
};

export default ChartView;
