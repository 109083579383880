import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { NUMBER_DATE_FORMAT } from '../pages/Flow/Utils/constants';
const useFilterOptions = (data, client, country) => {
  const [clientOptions, setClientOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [brandOptions, setBrandOptions] = useState([]);
  const [classificationOptions, setClassificationOptions] = useState();
  const [selectedStartDate, setSelectedStartDate] = useState('');
  const [selectedEndDate, setSelectedEndDate] = useState('');

  useEffect(() => {
    setClientOptions(data?.clients?.map((client) => client?.name)?.sort() ?? []);
  }, [data]);

  useEffect(() => {
    const selected = data?.clients?.filter((item) => item.name === client)?.[0] ?? [];
    setCountryOptions(selected?.country?.map((country) => country.name)?.sort() ?? []);
    if (client === 'BP') {
      setSelectedStartDate(dayjs('2023-04-02').format(NUMBER_DATE_FORMAT));
      setSelectedEndDate(dayjs('2024-09-01').format(NUMBER_DATE_FORMAT));
    } else if (client === 'NESTLE') {
      setSelectedStartDate(dayjs('2024-02-01').format(NUMBER_DATE_FORMAT));
      setSelectedEndDate(dayjs('2024-02-29').format(NUMBER_DATE_FORMAT));
    } else if (client === 'MARS') {
      setSelectedStartDate(dayjs('2024-04-01').format(NUMBER_DATE_FORMAT));
      setSelectedEndDate(dayjs('2024-04-30').format(NUMBER_DATE_FORMAT));
    } else if (client === 'LOREAL') {
      setSelectedStartDate(dayjs('2022-04-01').format(NUMBER_DATE_FORMAT));
      setSelectedEndDate(dayjs('2022-06-30').format(NUMBER_DATE_FORMAT));
    } else if (client === 'UNILEVER') {
      setSelectedStartDate(dayjs('2024-01-14').format(NUMBER_DATE_FORMAT));
      setSelectedEndDate(dayjs('2024-09-01').format(NUMBER_DATE_FORMAT));
    } else {
      setSelectedStartDate('');
      setSelectedEndDate('');
    }
  }, [data, client]);

  useEffect(() => {
    const selected =
      data?.clients?.filter((item) => item.name === client)?.[0]?.country?.filter((item) => item.name === country)[0] ??
      [];
    if (country) {
      setBrandOptions(selected?.brands?.map((brand) => brand.name)?.sort() ?? []);
      setClassificationOptions(selected?.classifications?.map((classification) => classification.name)?.sort() ?? []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, country]);

  return { clientOptions, countryOptions, brandOptions, classificationOptions, selectedStartDate, selectedEndDate };
};
export default useFilterOptions;
