import React, { useContext } from 'react';
import { WppSelect, WppListItem } from '@wppopen/components-library-react';
import useFilterOptions from '../../hooks/useFilterOptions';
import { ClientDataContext } from './PortfolioView';

const TableFilter = (props) => {
  const {
    data,
    client,
    country,
    selectedBrands,
    setSelectedBrands,
    selectedClassifications,
    setSelectedClassifications,
    selectedTags,
    setSelectedTags,
    setSearch,
  } = props;

  const { clientsData } = useContext(ClientDataContext);
  const { brandOptions, classificationOptions } = useFilterOptions(clientsData, client, country);

  return (
    <div className="m-4 flex flex-row ">
      <div>
        <WppSelect
          onWppChange={({ detail }) => setSearch(detail.value)}
          placeholder="Search ASIN"
          value=""
          size="m"
          labelConfig={{ text: '' }}
          required
          className="mx-4"
          withSearch={true}
          maxItemsToDisplay={1}
        >
          {data?.map((item, index) => (
            <WppListItem value={item?.asin} key={index}>
              <p slot="label">{item?.asin}</p>
            </WppListItem>
          ))}
        </WppSelect>
      </div>
      <WppSelect
        onWppChange={(e) => setSelectedBrands(e?.detail?.value)}
        placeholder="All Brands"
        value={selectedBrands}
        size="m"
        labelConfig={{ text: '' }}
        required
        className="mr-4"
        type="multiple"
        maxItemsToDisplay={1}
      >
        {brandOptions?.map((brand, index) => (
          <WppListItem value={brand} key={index}>
            <p slot="label">{brand}</p>
          </WppListItem>
        ))}
      </WppSelect>
      <WppSelect
        onWppChange={(e) => setSelectedClassifications(e?.detail?.value)}
        placeholder="All Categories"
        value={selectedClassifications}
        size="m"
        labelConfig={{ text: '' }}
        required
        className="mr-4"
        type="multiple"
        maxItemsToDisplay={1}
      >
        {classificationOptions?.map((classification, index) => (
          <WppListItem value={classification} key={index}>
            <p slot="label">{classification}</p>
          </WppListItem>
        ))}
      </WppSelect>
      {/*
      <WppSelect
        onWppChange={(e) => setSelectedTags(e?.detail?.value)}
        placeholder="All Tags"
        value={selectedTags}
        size="m"
        labelConfig={{ text: '' }}
        required
        className=""
        type="multiple"
        maxItemsToDisplay={1}
      >       
        {[]?.map((classification, index) => (
          <WppListItem value={classification} key={index}>
            <p slot="label">{classification}</p>
          </WppListItem>
        ))}
      </WppSelect>
      */}
    </div>
  );
};

export default TableFilter;
