import { useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import dayjs from 'dayjs';
import { REQUEST_STATUS } from './useFetchClients';
import { PORTFOLIO_REPORT_DATE_FORMAT } from '../pages/constants';

const baseApi = process.env.REACT_APP_API_ENDPOINT;

const useFetchChartData = () => {
  const [data, setData] = useState([]);
  const [requestStatus, setRequestStatus] = useState(null);
  const [requestError, setRequestError] = useState(null);
  const { oktaAuth } = useOktaAuth();
  const accessToken = oktaAuth.getIdToken();

  const fetchData = async (client, country, startDate, endDate, xAxis, yAxis, nullIncluded) => {
    try {
      const payload = {
        client,
        start_date: dayjs(startDate).format(PORTFOLIO_REPORT_DATE_FORMAT),
        end_date: dayjs(endDate).format(PORTFOLIO_REPORT_DATE_FORMAT),
        country,
        axis: [xAxis, yAxis],
        nullIncluded,
      };
      setRequestStatus(REQUEST_STATUS.LOADING);
      const rcvd = await fetch(`${baseApi}/portfolio/report`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(payload),
      });
      const response = await rcvd.json();
      setData(response);
      setRequestStatus(REQUEST_STATUS.SUCCESS);
    } catch (error) {
      setRequestStatus(REQUEST_STATUS.FAILURE);
      setRequestError(error);
      console.log('API FETCH ERROR: ', error);
    }
  };

  return { fetchData, data, requestStatus, requestError };
};

export default useFetchChartData;
