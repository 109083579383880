import React from 'react';
import { WppTypography, WppTabs, WppTab } from '@wppopen/components-library-react';

const ChartHeader = ({ chartType, setChartType, data }) => {
  const { client, startDate, endDate } = data;
  const date = `${startDate} - ${endDate}`;

  return (
    <div className="ml-5">
      <WppTypography type="s-strong" slot="header">
        {client}
      </WppTypography>
      <div className="mt-2">
        <WppTypography type="xs-body" slot="header">
          {date}
        </WppTypography>
      </div>

      <div className="w-[30%] mt-3">
        <WppTabs value={chartType} onWppChange={(event) => setChartType(event.detail.value)}>
          <WppTab value="brand">Brands</WppTab>
          <WppTab value="classification">Categories</WppTab>
          <WppTab value="products"> Products </WppTab>
        </WppTabs>
      </div>
    </div>
  );
};

export default ChartHeader;
