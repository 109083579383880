import React, { useContext } from 'react';
import {
  WppInput,
  WppSelect,
  WppListItem,
  WppButton,
  WppAccordion,
  WppTypography,
} from '@wppopen/components-library-react';

import useFilterOptions from '../../hooks/useFilterOptions';
import { ClientDataContext } from './PortfolioView';

const ProductFilter = (props) => {
  const {
    selectedBrands,
    handleSelectedBrands,
    selectedClassifications,
    handleSelectedClassifications,
    xAxisMarkerType,
    setXAxisMarkerType,
    yAxisMarkerType,
    setYAxisMarkerType,
    xAxisMarker,
    setXAxisMarker,
    yAxisMarker,
    setYAxisMarker,
    quadrants,
    setQuadrants,
    handleExport,
    client,
    country,
    axisLabels: { xAxis, yAxis },
    data,

    zoomToItem,
  } = props;

  const { clientsData } = useContext(ClientDataContext);
  const { brandOptions, classificationOptions } = useFilterOptions(clientsData, client, country);

  const handleSearch = (value) => {
    const searched = data.find((item) => item?.[4]?.asin === value);
    if (searched) {
      zoomToItem(searched);
    }
  };

  return (
    <div className="m-4">
      <WppSelect
        onWppChange={({ detail }) => handleSearch(detail.value)}
        placeholder="Search ASIN"
        value=""
        size="m"
        labelConfig={{ text: '' }}
        required
        className="w-full mt-2"
        withSearch={true}
        maxItemsToDisplay={1}
      >
        {data?.map((item, index) => (
          <WppListItem value={item?.[4]?.asin} key={index}>
            <p slot="label">{item?.[4]?.asin}</p>
          </WppListItem>
        ))}
      </WppSelect>
      <WppSelect
        onWppChange={(e) => handleSelectedBrands(e?.detail?.value)}
        placeholder="All Brands"
        value={selectedBrands}
        size="m"
        labelConfig={{ text: 'Highlight Brands' }}
        required
        className="w-full mt-2"
        type="multiple"
        maxItemsToDisplay={1}
        disabled={selectedClassifications.length > 0}
      >
        {brandOptions?.map((brand, index) => (
          <WppListItem value={brand} key={index}>
            <p slot="label">{brand}</p>
          </WppListItem>
        ))}
      </WppSelect>

      <WppSelect
        onWppChange={(e) => handleSelectedClassifications(e?.detail?.value)}
        placeholder="All Classifications"
        value={selectedClassifications}
        size="m"
        labelConfig={{ text: 'Highlight Classifications' }}
        required
        className="w-full mt-2"
        type="multiple"
        maxItemsToDisplay={1}
        disabled={selectedBrands.length > 0}
      >
        {classificationOptions?.map((classification, index) => (
          <WppListItem value={classification} key={index}>
            <p slot="label">{classification}</p>
          </WppListItem>
        ))}
      </WppSelect>
      <WppAccordion className="mt-3">
        <WppTypography type="xs-strong" slot="header">
          Adjust Quadrant Lines
        </WppTypography>
        <div className="flex flex-col">
          <WppSelect
            onWppChange={(e) => setXAxisMarkerType(e.detail.value)}
            placeholder="Select marker type"
            value={xAxisMarkerType}
            labelConfig={{ text: xAxis }}
            required
            className="w-full mt-2"
            size="m"
          >
            <WppListItem value="mean">
              <p slot="label">Mean</p>
            </WppListItem>
            <WppListItem value="median">
              <p slot="label">Median</p>
            </WppListItem>
            <WppListItem value="custom">
              <p slot="label">Custom</p>
            </WppListItem>
          </WppSelect>
          {xAxisMarkerType === 'custom' ? (
            <WppInput
              name="xAxisMarker"
              labelConfig={{ text: '' }}
              placeholder="Enter xAxis marker"
              value={xAxisMarker}
              required
              onWppChange={(e) => setXAxisMarker(e.detail.value)}
              className="mt-2"
              type="number"
            ></WppInput>
          ) : null}

          <WppSelect
            onWppChange={(e) => setYAxisMarkerType(e.detail.value)}
            placeholder="Select marker type"
            value={yAxisMarkerType}
            labelConfig={{ text: yAxis }}
            required
            className="w-full mt-2"
            size="m"
          >
            <WppListItem value="mean">
              <p slot="label">Mean</p>
            </WppListItem>
            <WppListItem value="median">
              <p slot="label">Median</p>
            </WppListItem>
            <WppListItem value="custom">
              <p slot="label">Custom</p>
            </WppListItem>
          </WppSelect>

          {yAxisMarkerType === 'custom' ? (
            <WppInput
              name="yAxisMarker"
              labelConfig={{ text: '' }}
              placeholder="Enter yAxis marker"
              value={yAxisMarker}
              required
              onWppChange={(e) => setYAxisMarker(e.detail.value)}
              className="mt-2"
              type="number"
            ></WppInput>
          ) : null}
        </div>
      </WppAccordion>

      <WppAccordion className="mt-3">
        <WppTypography type="xs-strong" slot="header">
          Export by Quadrant
        </WppTypography>
        <div className="flex flex-col">
          <WppSelect
            onWppChange={(e) => setQuadrants(e.detail.value)}
            placeholder="Select quadrant"
            value={quadrants}
            labelConfig={{ text: 'Quadrant' }}
            required
            className="w-full mt-2"
            size="m"
            type="multiple"
            maxItemsToDisplay={1}
            //disabled={!(xAxisMarker && yAxisMarker)}
          >
            <WppListItem value="quadranttr">
              <p slot="label">Quadrant 1</p>
            </WppListItem>
            <WppListItem value="quadranttl">
              <p slot="label">Quadrant 2</p>
            </WppListItem>
            <WppListItem value="quadrantbl">
              <p slot="label">Quadrant 3</p>
            </WppListItem>
            <WppListItem value="quadrantbr">
              <p slot="label">Quadrant 4</p>
            </WppListItem>
          </WppSelect>
        </div>
      </WppAccordion>

      <WppButton size="s" onClick={handleExport} className="mt-2">
        Export
      </WppButton>
    </div>
  );
};

export default ProductFilter;
