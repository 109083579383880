import React, { useContext } from 'react';
import { WppTypography, WppDatepicker, WppSelect, WppListItem, WppAccordion, WppToggle } from '@wppopen/components-library-react';
import useFilterOptions from '../../hooks/useFilterOptions';
import { ClientDataContext } from './PortfolioView';
import { WPPOPEN_LOCALE_DATE_FORMAT } from '../Flow/Utils/constants';

const FilterView = (props) => {
  const {
    client,
    setClient,
    country,
    setCountry,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    xAxis,
    setXAxis,
    yAxis,
    setYAxis,
    nullIncluded,
    setNullIncluded,
  } = props;

  const { clientsData } = useContext(ClientDataContext);

  const { clientOptions, countryOptions, /*xAxisOptions, yAxisOptions*/ } =
    useFilterOptions(clientsData, client);

  return (
    <div>
      <WppTypography type="s-strong" slot="header">
        Portfolio Planner
      </WppTypography>
      <div className="mt-6">
        <WppAccordion size="2xl">
          <WppTypography type="xs-strong" slot="header">
            Edit Selection
          </WppTypography>
          <div className="flex flex-col">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-10  gap-x-1 w-full">
              <div className="md:col-span-3">
                <WppSelect
                  onWppChange={(e) => setClient(e?.detail?.value)}
                  placeholder="Select Client"
                  value={client}
                  size="m"
                  labelConfig={{ text: 'Client' }}
                  required
                  className="w-full px-2"
                >
                  {clientOptions?.map((client, index) => (
                    <WppListItem value={client} key={index}>
                      <p slot="label">{client}</p>
                    </WppListItem>
                  ))}
                </WppSelect>
              </div>
              <div className="md:col-span-3">
                <WppSelect
                  onWppChange={(e) => setCountry(e.detail.value)}
                  placeholder="Select Market"
                  value={country}
                  labelConfig={{ text: 'Country' }}
                  required
                  className="w-full px-2"
                >
                  {countryOptions?.map((country, index) => (
                    <WppListItem value={country} key={index}>
                      <p slot="label">{country}</p>
                    </WppListItem>
                  ))}
                </WppSelect>
              </div>
              <div className="md:col-span-2">
                <WppDatepicker
                  labelConfig={{ text: 'Starting Month' }}
                  placeholder="Select Month"
                  value={startDate}
                  onWppChange={(e) => setStartDate(e.detail.formattedDate)}
                  onWppDateClear={() => setStartDate('')}
                  locale={{ dateFormat: WPPOPEN_LOCALE_DATE_FORMAT }}
                  required
                />
              </div>
              <div className="md:col-span-2">
                <WppDatepicker
                  labelConfig={{ text: 'Ending Month' }}
                  placeholder="Select Month"
                  value={endDate}
                  onWppChange={(e) => setEndDate(e.detail.formattedDate)}
                  onWppDateClear={() => setEndDate('')}
                  locale={{ dateFormat: WPPOPEN_LOCALE_DATE_FORMAT }}
                  required={true}
                />
              </div>
            </div>

            <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-10 gap-x-1 ">
              <div className="md:col-span-3">
                <WppSelect
                  onWppChange={(e) => setXAxis(e?.detail?.value)}
                  placeholder="Select X-axix Metric"
                  value={xAxis}
                  labelConfig={{ text: 'X-axis' }}
                  required
                  className="w-full px-2"
                >
                  <WppListItem value="conversion rate">
                    <p slot="label">ConversionRate</p>
                  </WppListItem>
                  <WppListItem value="net ppm">
                    <p slot="label">NetPPM</p>
                  </WppListItem>
                  <WppListItem value="revenue">
                    <p slot="label">Revenue</p>
                  </WppListItem>
                  <WppListItem value="on hand inventory">
                    <p slot="label">OnHandInventory</p>
                  </WppListItem>
                </WppSelect>
              </div>
              <div className="md:col-span-3">
                <WppSelect
                  onWppChange={(e) => setYAxis(e?.detail?.value)}
                  placeholder="Select Y-axix Metric"
                  value={yAxis}
                  labelConfig={{ text: 'Y-axis' }}
                  required
                  className="w-full px-2"
                >
                  <WppListItem value="conversion rate">
                    <p slot="label">ConversionRate</p>
                  </WppListItem>
                  <WppListItem value="net ppm">
                    <p slot="label">NetPPM</p>
                  </WppListItem>
                  <WppListItem value="revenue">
                    <p slot="label">Revenue</p>
                  </WppListItem>
                  <WppListItem value="on hand inventory">
                    <p slot="label">OnHandInventory</p>
                  </WppListItem>
                </WppSelect>
              </div>
              <div className="md:col-span-3 flex items-center mt-6">
                <div className="text-sm font-normal">Include Null Values</div>
                <WppToggle
                  className="ml-3"
                  checked={nullIncluded}
                  name="optionsSimilarWeb"
                  onWppChange={({ detail: { checked } }) => setNullIncluded(checked)}
                />
              </div>
            </div>
          </div>
        </WppAccordion>
      </div>
    </div>
  );
};

export default FilterView;
