import React, { useEffect, createContext } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { jwtDecode } from 'jwt-decode';
import { WppSpinner } from '@wppopen/components-library-react';
import Header from '../../components/Header';
import SideBar from '../../components/SideBar';
import useFetchClients from '../../hooks/useFetchClients';
import { REQUEST_STATUS } from '../../hooks/useFetchClients';
import ReportView from './ReportView';
import './HomePage.css';

export const ClientDataContext = createContext(null);

const PortfolioView = () => {
  const { oktaAuth } = useOktaAuth();
  const accessToken = oktaAuth.getIdToken();
  const decodedToken = jwtDecode(accessToken);
  const userPermissions = decodedToken.groupsFusion;

  const { clientsData, requestStatus } = useFetchClients();

  useEffect(() => {
    setTimeout(() => {}, 10); //Clarify why wating here
  }, []);

  return (
    <div style={{ height: 500, width: '100%', position: 'relative' }}>
      <Header title="Fusion.Portfolio" />
      <div className={'flex w-full justify-start items-start'}>
        <SideBar active={'portfolio'} userPermissions={userPermissions} />
        <div className={'flex pb-32 flex-col items-center w-full h-full ml-[80px] bg-gray-50'}>
          {requestStatus === REQUEST_STATUS.LOADING ? (
            <div className="flex items-center justify-center mt-6">
              <WppSpinner size="l" color="var(--wpp-primary-color-500)" />{' '}
            </div>
          ) : (
            <ClientDataContext.Provider value={{ clientsData }}>
              <ReportView />
            </ClientDataContext.Provider>
          )}
        </div>
      </div>
    </div>
  );
};

export default PortfolioView;
