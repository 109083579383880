import React, { useState } from 'react';
import { WppButton } from '@wppopen/components-library-react';
import ProductScatterChart from './charts/ProductScatterChart';
import TableView from './TableView';

const ProductVeiw = (props) => {
  const [viewType, setViewType] = useState('chart');
  return (
    <div>
      <div className="flex justify-end mx-5">
        <WppButton
          size="s"
          onClick={() => setViewType('chart')}
          className={`mt-2 ${viewType === 'chart' ? 'bg-blue-300' : ''}`}
          variant="secondary"
        >
          Chart View
        </WppButton>
        <WppButton
          size="s"
          onClick={() => setViewType('table')}
          className={`mt-2 ${viewType === 'table' ? 'bg-blue-300' : ''}`}
          variant="secondary"
        >
          Table View
        </WppButton>
      </div>
      {viewType === 'chart' ? (
        <ProductScatterChart {...props} />
      ) : viewType === 'table' ? (
        <TableView {...props} />
      ) : null}
    </div>
  );
};

export default ProductVeiw;
