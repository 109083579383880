import React, { useState, useRef, useEffect, useMemo } from 'react';
import ReactEcharts from 'echarts-for-react';

import {
  computeMarkers,
  transformData,
  generateSeries,
  downloadCSV,
  generateExportData,
  updateDataZoom,
  generateChartSeries,
} from '../../../utils/echartsUtils';

import ProductDetails from '../ProductDetails';
import ProductFilter from '../ProductFilter';

const ProductScatterChart = (props) => {
  const { data, xAxis, yAxis, client, country, selectedItem } = props;
  const transformedData = useMemo(() => transformData(data, xAxis, yAxis), [data, xAxis, yAxis]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedClassifications, setSelectedClassifications] = useState([]);
  const [filterType, setFilterType] = useState('');
  const [xAxisMarkerType, setXAxisMarkerType] = useState('mean');
  const [yAxisMarkerType, setYAxisMarkerType] = useState('mean');
  const [xAxisMarker, setXAxisMarker] = useState('');
  const [yAxisMarker, setYAxisMarker] = useState('');
  const [quadrants, setQuadrants] = useState([]);
  const [zoomState, setZoomState] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeProduct, setActiveProduct] = useState('');
  const [xDivider, setXDivider] = useState('');
  const [yDivider, setYDivider] = useState('');

  const chartRef = useRef(null);

  const zoomToItem = (params) => {
    const [xValue, yValue] = params ?? [];
    const item = params?.[4] ?? {};
    const zoomData = { xStart: xValue - 30, xEnd: xValue + 30, yStart: yValue - 30, yEnd: yValue + 30 };

    setZoomState(zoomData);
    setActiveProduct(item);
    setIsModalOpen(true);
  };

  const handleSelected = (params) => {
    const item = params?.data?.[4] || params;
    if (activeProduct !== item?.asin) {
      const chart = chartRef.current.getEchartsInstance();
      const {
        activeXAxis: [xStart, xEnd],
        activeYAxis: [yStart, yEnd],
      } = getActiveAxis(chart);
      const zoomData = { xStart, xEnd, yStart, yEnd };
      setZoomState(zoomData);
      setActiveProduct(item);
      setIsModalOpen(true);
    }
  };

  const handleSelectedBrands = (data) => {
    setSelectedBrands(data);
    setFilterType(data?.length > 0 ? 'brand' : '');
  };

  const handleSelectedClassifications = (value) => {
    setSelectedClassifications(value);
    setFilterType(data?.length > 0 ? 'classification' : '');
  };

  const getActiveAxis = (chart) => {
    const activeXAxis = chart.getModel().getComponent('xAxis').axis.scale.getExtent();
    const activeYAxis = chart.getModel().getComponent('yAxis').axis.scale.getExtent();

    return { activeXAxis, activeYAxis };
  };

  const handleExport = () => {
    const chart = chartRef.current.getEchartsInstance();
    const { activeXAxis, activeYAxis } = getActiveAxis(chart);
    const activeMarkers = { xDivider, yDivider };
    const selectedFilter =
      selectedBrands?.length > 0 ? selectedBrands : selectedClassifications?.length > 0 ? selectedClassifications : [];
    const exportData = generateExportData(
      transformedData,
      activeXAxis,
      activeYAxis,
      activeMarkers,
      selectedFilter,
      filterType,
      quadrants
    );
    downloadCSV(exportData);
  };

  const initialOption = {
    grid: {
      left: '10%',
      right: '10%',
      top: '10%',
      containLabel: true,
    },

    tooltip: {
      position: 'top',
      formatter: (params) => {
        const [xAxisValue, yAxisValue, brand, classification, details, xAxis, yAxis] = Array.isArray(params?.data)
          ? params?.data
          : [];
        return `
           <strong>${brand}</strong><br/>
           <strong>${classification}</strong><br/>
           ASIN: ${details?.asin ?? ''}<br/>
           ${xAxis}: ${xAxisValue}<br/>
           ${yAxis}: ${yAxisValue}<br/>
          `;
      },
    },
    xAxis: {
      type: 'value',
      name: xAxis,
      nameLocation: 'center',
      nameGap: 30,
      splitLine: {
        show: true,
      },
    },
    yAxis: {
      type: 'value',
      name: yAxis,
      nameLocation: 'center',
      nameGap: 30,
      splitLine: {
        show: true,
      },
    },

    dataZoom: [
      {
        type: 'slider',
        id: 'xzoomslider',
        show: true,
        xAxisIndex: 0,
        top: 'bottom',
        showDataShadow: true,
      },
      {
        type: 'slider',
        id: 'yzoomslider',
        show: true,
        yAxisIndex: 0,
        left: '93%',
      },
      {
        type: 'inside',
        id: 'xzoominside',
        xAxisIndex: 0,
      },
      {
        type: 'inside',
        id: 'yzoominside',
        yAxisIndex: 0,
      },
    ],
  };
  const onEvents = {
    click: handleSelected,
  };

  useEffect(() => {
    const chart = chartRef.current.getEchartsInstance();
    const updateMarkerLines = () => {
      const { series, selectedFilter } = generateChartSeries(
        transformedData,
        selectedBrands,
        selectedClassifications,
        xDivider,
        yDivider,
        quadrants,
        filterType,
        activeProduct,
        {}
      );
      chart.setOption({
        legend: {
          data: selectedFilter,
        },
        series,
        ...(filterType && { color: '#0A2FFF' }),
      });
    };
    chart.on('dataZoom', updateMarkerLines);
    updateMarkerLines();
    return () => {
      chart.off('dataZoom', updateMarkerLines);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { type, value } = selectedItem;
    if (type && value) {
      if (type === 'brand') {
        setSelectedBrands([...selectedBrands, value]);
        setFilterType(type);
      } else if (type === 'classification') {
        setSelectedClassifications([...selectedClassifications, value]);
        setFilterType(type);
      }
    } else {
      const chart = chartRef?.current?.getEchartsInstance();
      const series = [generateSeries(transformedData)] ?? [];
      chart.setOption({
        series,
        color: '#0A2FFF',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { xDivider } = computeMarkers(transformedData, xAxisMarkerType, yAxisMarkerType, xAxisMarker, yAxisMarker);
    setXDivider(xDivider);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [xAxisMarkerType, xAxisMarker]);

  useEffect(() => {
    const { yDivider } = computeMarkers(transformedData, xAxisMarkerType, yAxisMarkerType, xAxisMarker, yAxisMarker);
    setYDivider(yDivider);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yAxisMarkerType, yAxisMarker]);

  useEffect(() => {
    const chart = chartRef.current.getEchartsInstance();
    const activeAxis = getActiveAxis(chart);
    const { series, selectedFilter } = generateChartSeries(
      transformedData,
      selectedBrands,
      selectedClassifications,
      xDivider,
      yDivider,
      quadrants,
      filterType,
      activeProduct,
      activeAxis
    );
    const zoom = updateDataZoom(zoomState);
    chart.setOption({
      legend: {
        data: selectedFilter,
      },
      ...(zoomState ? { dataZoom: zoom } : {}),
      series,
      ...(selectedFilter.lenght === 0 && { color: '#0A2FFF' }),
    });

    if (quadrants.length > 0) {
      const activeAxis = getActiveAxis(chart);
      const { series, selectedFilter } = generateChartSeries(
        transformedData,
        selectedBrands,
        selectedClassifications,
        xDivider,
        yDivider,
        quadrants,
        filterType,
        activeProduct,
        activeAxis
      );
      const zoom = updateDataZoom(zoomState);
      chart.setOption({
        legend: {
          data: selectedFilter,
        },
        ...(zoomState ? { dataZoom: zoom } : {}),
        series,
        ...(selectedFilter.lenght === 0 && { color: '#0A2FFF' }),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBrands, selectedClassifications, quadrants, isModalOpen, activeProduct, xDivider, yDivider]);

  return (
    <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-10 gap-x-1">
      <div className="md:col-span-8">
        <ReactEcharts
          ref={chartRef}
          option={initialOption}
          style={{ minHeight: '400px', width: '100%' }}
          onEvents={onEvents}
        />
      </div>
      <div className="md:col-span-2">
        <ProductFilter
          selectedBrands={selectedBrands}
          handleSelectedBrands={handleSelectedBrands}
          selectedClassifications={selectedClassifications}
          handleSelectedClassifications={handleSelectedClassifications}
          xAxisMarkerType={xAxisMarkerType}
          setXAxisMarkerType={setXAxisMarkerType}
          yAxisMarkerType={yAxisMarkerType}
          setYAxisMarkerType={setYAxisMarkerType}
          xAxisMarker={xAxisMarker}
          setXAxisMarker={setXAxisMarker}
          yAxisMarker={yAxisMarker}
          setYAxisMarker={setYAxisMarker}
          quadrants={quadrants}
          setQuadrants={setQuadrants}
          handleExport={handleExport}
          client={client}
          country={country}
          axisLabels={{ xAxis, yAxis }}
          data={transformedData}
          zoomToItem={zoomToItem}
        />
      </div>
      <div>
        <ProductDetails isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} activeProduct={activeProduct} />
      </div>
    </div>
  );
};

export default ProductScatterChart;
