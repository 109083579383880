import React, { useState, useEffect } from 'react';

import { WppTypography, WppSideModal, WppPaginationSelect, WppDivider } from '@wppopen/components-library-react';

const ProductDetails = ({ isModalOpen, setIsModalOpen, activeProduct }) => {
  const [products, setProducts] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(1);
  const [currentProduct, setCurrentProduct] = useState('');

  const addProduct = (product) => {
    const updatedList = [...products, product];
    if (updatedList.length > 5) {
      updatedList.shift();
    }
    setProducts(updatedList);
    setCurrentIndex(updatedList.length);
  };

  const handleProductIndex = (page) => {
    setCurrentIndex(page);
    setCurrentProduct(products?.[page - 1] ?? '');
  };
  useEffect(() => {
    if (activeProduct) {
      const { asin } = activeProduct;
      if (!products?.find((item) => item.asin === asin)) {
        addProduct(activeProduct);
        setCurrentProduct(activeProduct);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen, activeProduct]);
  return (
    <div>
      <WppSideModal
        open={isModalOpen}
        onWppSideModalClose={() => setIsModalOpen(false)}
        disableOutsideClick={false}
        size="s"
      >
        <div slot="header" className="flex flex-col">
          <WppPaginationSelect
            count={products.length}
            activePageNumber={currentIndex}
            pageSelectThreshold={0}
            onWppChange={({ detail }) => handleProductIndex(detail?.page)}
          />
        </div>

        <div slot="body" className="flex flex-col">
          <WppTypography tag="h6" type="m-strong" className="self-center my-2">
            Product Details
          </WppTypography>

          <div>{currentProduct?.itemName}</div>
          {/*
          <div className="self-center my-5">
            <img src="https://picsum.photos/150/150" alt="Product" />
          </div>
          */}
          <ul className="bg-[#F8F9FB]">
            {Object.keys(currentProduct)
              ?.filter((item) => item !== 'itemName')
              ?.map((item) => (
                <>
                  <li className="p-2">
                    <div className="flex">
                      <div className="w-[50%]">
                        <WppTypography tag="h6" type="m-strong">
                          {item}
                        </WppTypography>
                      </div>
                      <div className="w-[50%]">{currentProduct[item]}</div>
                    </div>
                  </li>
                  <WppDivider></WppDivider>
                </>
              ))}
          </ul>
        </div>
      </WppSideModal>
    </div>
  );
};

export default ProductDetails;
